<template>
  <tag-detail />
</template>

<script>
import TagDetail from './apps/e-commerce/e-commerce-product-details/ECommerceTagDetails.vue'

export default {
  components: {
    TagDetail,
  },
}
</script>

<style>

</style>
